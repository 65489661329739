import * as React from "react"
import Layout from "../components/Layout";
import "bootstrap/dist/css/bootstrap.css"
import Button from "react-bootstrap/cjs/Button"
import Form from "react-bootstrap/cjs/Form"
import Row from "react-bootstrap/cjs/Row"
import Col from "react-bootstrap/cjs/Col"
import logo from "../images/gearinheadlights.png"
import Airtable from "airtable"
import SEO from "../components/Seo";
import {toast, MDBToastContainer} from "mdbreact";

Airtable.configure({
    apiKey: process.env.GATSBY_AIRTABLE_API_KEY,
    endpointUrl: 'https://api.airtable.com',
    noRetryIfRateLimited: false,
    apiVersion: undefined
})

interface AppProps {
    location: any;
}

interface AppState {
    email: string
}

export default class IndexPage extends React.Component<AppProps, AppState> {
    constructor(props: AppProps) {
        super(props)

        this.state = {
            email: ""
        }
    }

    gtag_report_conversion(url: any): boolean {
        const callback = function () {
            if (typeof (url) != 'undefined') {
                window.location = url;
            }
        };
        gtag('event', 'conversion', {
            'send_to': `${process.env.GATSBY_GOOGLE_ADS_ID}/${process.env.GATSBY_GOOGLE_ADS_CONVERSION_ID}`,
            'event_callback': callback
        });
        return false;
    }

    handleSubmit = (event: any) => {
        this.gtag_report_conversion("https://gearinheadlights.com")
        toast.success('Thank you for joining the waitlist!', {
            closeButton: false
        });
        let base = new Airtable().base(process.env.GATSBY_AIRTABLE_WAITLIST_EMAILS_BASE_ID || "none");
        const fields = {"fields": {"Email": this.state.email}}
        this.setState({
            email: ""
        })
        base('waitlist_emails').create([
            fields
        ], function (err: any, records: any) {
            console.log(records)
            if (err) {
                console.error(err);
                return;
            }
            records.forEach(function (record: any) {
                console.log(record)
                console.log(record.getId());
            });
        })

        console.log(typeof event)
        event.preventDefault();
        event.target.reset();
    }

    handleEmailChange = (event: any) => {
        this.setState({
            email: event.target.value
        })
    }

    render() {
        return (
            // <div className='bg'>
            <Layout location={this.props.location}>
                <SEO title="gear in headlights - personal outdoor gear rental"/>
                <div className="container">
                    <div>
                        <Row className="justify-content-center align-self-center">
                            <Col className="logo-column" sm={4}>
                                <h1 className="logo-header-full text-center">
                                    <img
                                        className="img-logo pb-3"
                                        src={logo}
                                        alt="gearinheadlights"
                                    />
                                </h1>
                                <h2 className="text-center">Do you own outdoor gear?</h2>
                                <p className=" text-center mb-0">
                                    Earn money renting out your gear. Join the gearkeeper
                                    waitlist and gain exclusive access.
                                </p>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col className="logo-column mt-3 mb-3" sm={4}>
                                <Form onSubmit={this.handleSubmit}>
                                    <Form.Group controlId="email">
                                        <Form.Control type="email" placeholder="Enter email"
                                                      onChange={this.handleEmailChange}/>
                                    </Form.Group>
                                    <Button className="text-center shop-btn shadow" type="submit">
                                        JOIN THE WAITLIST
                                    </Button>
                                    {/*<p className="more-info-p justify-content-center pt-1">*/}
                                    {/*    <a className="more-info-link"*/}
                                    {/*       href="https://gearinheadlights.com/about">*/}
                                    {/*        More information*/}
                                    {/*    </a>*/}
                                    {/*</p>*/}
                                    <MDBToastContainer
                                        hideProgressBar={true}
                                        newestOnTop={true}
                                        autoClose={5000}
                                    />
                                </Form>
                                {/*<h3 className="text-center pt-2">*/}
                                {/*    <a href="https://instagram.com/gearinheadlights">*/}
                                {/*        <i className="fab fa-instagram social-icon"/>*/}
                                {/*    </a>*/}
                                {/*    <a href="https://facebook.com/gearinheadlights">*/}
                                {/*        <i className="fab fa-facebook-f social-icon"/>*/}
                                {/*    </a>*/}
                                {/*</h3>*/}
                            </Col>
                        </Row>
                    </div>
                </div>
            </Layout>
            // </div>
        )
    }
};
